<template>
	<div class="course-mobile" v-if="isMobile">
		<img src="../assets/img/kczx.png" class="img-banner">
		<div class="main-content">
			<div class="row-me tab-div">
				<div class="column-me center-all left-div">
					<div class="title1">课程中心</div>
					<div class="title2">COURSE</div>
				</div>
				<div class="row-me row-center flex-wrap flex1">
					<div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">训练课程</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">研学拓展</div>
					<!-- <div class="tab-line"></div> -->
					<div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">赛事组织</div>
				</div>
			</div>
			<!-- 训练课程 -->
			<div class="column-me row-center" v-if="tabIndex===0">
				<div class="row-me center-all margin-top50">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">训练课程</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">TRAINING COURSE</div>
				<div class="introduce margin-top50">
					卡丁车被誉为“F1赛车手的摇篮”，很多顶级赛车手都在卡丁车的座椅上得到启蒙。作为赛车运动的一种，卡丁车也是一项充满魅力的竞技运动，为了让孩子得到专业的启蒙教育，并以培养专业赛车手为目标，红尾狐卡丁组建了一支包括儿童体能训练专家、职业赛车手、儿童行为学学者在内的课程研发团队，先后推出了儿童卡丁车三能课程、全尺寸卡丁车STEAM构建课、迷你卡丁车创造力组装课，并在旗下训练中心开设系列训练课程。
					红尾狐卡丁的培训课程，在提升孩子身体协调性、增强身体素质、促进骨骼发育的同时也进一步增强孩子的专注力和逻辑思维能力，促进智力发展。通过成体系的训练，提升孩子的驾驶技能，塑造自信勇敢的性格。
				</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(0)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===0">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon1.png" class="img-icon">
							<div class="title11">儿童卡丁车三能课程</div>
						</div>
					</div>
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(1)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===1">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon2.png" class="img-icon">
							<div class="title11">全尺寸卡丁车STEAM构建课</div>
						</div>
					</div>
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(2)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===2">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon3.png" class="img-icon">
							<div class="title11">迷你卡丁车创造力组装课</div>
						</div>
					</div>
				</div>
				<div class="column-me margin-top50 width100Per" v-show="xunIndex===0">
					<div class="column-me row-center width100Per">
						<el-image :src="er1" :preview-src-list="[er1]" fit="cover" hide-on-click-modal class="img1">
						</el-image>
						<el-image :src="er2" :preview-src-list="[er2]" fit="cover" hide-on-click-modal
							class="img2 margin-top20">
						</el-image>
					</div>
					<div class="column-me row-center margin-top20">
						<el-image :src="er4" :preview-src-list="[er4]" fit="cover" hide-on-click-modal
							class="img4 margin-top20">
						</el-image>
						<el-image :src="er5" :preview-src-list="[er5]" fit="cover" hide-on-click-modal
							class="img5 margin-top20">
						</el-image>
						<el-image :src="er6" :preview-src-list="[er6]" fit="cover" hide-on-click-modal
							class="img6 margin-top20">
						</el-image>
					</div>
					<div class="column-me row-center margin-top20">
						<el-image :src="er7" :preview-src-list="[er7]" fit="cover" hide-on-click-modal class="img7">
						</el-image>
						<el-image :src="er8" :preview-src-list="[er8]" fit="cover" hide-on-click-modal
							class="img8 margin-top20">
						</el-image>
						<el-image :src="er9" :preview-src-list="[er9]" fit="cover" hide-on-click-modal
							class="img9 margin-top20">
						</el-image>
						<el-image :src="er10" :preview-src-list="[er10]" fit="cover" hide-on-click-modal
							class="img10 margin-top20">
						</el-image>
					</div>
				</div>
				<div class="column-me margin-top50 width100Per" v-show="xunIndex===1">
					<div class="column-me row-center">
						<el-image :src="quan1" :preview-src-list="[quan1]" fit="cover" hide-on-click-modal
							class="img-quan1">
						</el-image>
						<el-image :src="quan2" :preview-src-list="[quan2]" fit="cover" hide-on-click-modal
							class="img-quan2 margin-top20">
						</el-image>
					</div>
				</div>
				<div class="column-me margin-top50 width100Per" v-show="xunIndex===2">
					<div class="column-me row-center">
						<el-image :src="mi1" :preview-src-list="[mi1]" fit="cover" hide-on-click-modal class="img-mi1">
						</el-image>
						<el-image :src="mi2" :preview-src-list="[mi2]" fit="cover" hide-on-click-modal
							class="img-mi2 margin-top20">
						</el-image>
						<el-image :src="mi3" :preview-src-list="[mi3]" fit="cover" hide-on-click-modal
							class="img-mi3 margin-top20">
						</el-image>
					</div>
					<div class="column-me row-center margin-top20">
						<el-image :src="mi4" :preview-src-list="[mi4]" fit="cover" hide-on-click-modal class="img-mi4">
						</el-image>
						<el-image :src="mi5" :preview-src-list="[mi5]" fit="cover" hide-on-click-modal
							class="img-mi5 margin-top20">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 研学拓展 -->
			<div class="column-me row-center" v-if="tabIndex===1">
				<div class="row-me center-all margin-top50">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">研学拓展</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">STUDIES EXPAND</div>
				<div class="intro-text margin-top70">
					红尾狐卡丁将卡丁车运动和研学拓展进一步结合，依托几处大型训练中心，整合多方资源，开发了几条特色研学路线。拥抱大海，学习帆船驾驶、体验海岛宿营、海洋文化科普；探索冰雪世界，学习滑雪滑冰技能；了解马背文化，感受广阔无垠的大草原。这些内容丰富的研学活动不仅开阔了俱乐部会员的视野，同时也提供了多样的学习体验。
				</div>
				<div class="column-me width100Per margin-top40 flex-sb">
					<div class="column-me">
						<el-image :src="yan1" :preview-src-list="[yan1]" fit="cover" hide-on-click-modal
							class="img-yan1">
						</el-image>
						<el-image :src="yan3" :preview-src-list="[yan3]" fit="cover" hide-on-click-modal
							class="img-yan3 margin-top20">
						</el-image>
						<el-image :src="yan5" :preview-src-list="[yan5]" fit="cover" hide-on-click-modal
							class="img-yan5 margin-top20">
						</el-image>
						<el-image :src="yan7" :preview-src-list="[yan7]" fit="cover" hide-on-click-modal
							class="img-yan7 margin-top20">
						</el-image>
					</div>
					<div class="column-me margin-top20">
						<el-image :src="yan2" :preview-src-list="[yan2]" fit="cover" hide-on-click-modal
							class="img-yan2">
						</el-image>
						<el-image :src="yan4" :preview-src-list="[yan4]" fit="cover" hide-on-click-modal
							class="img-yan4 margin-top20">
						</el-image>
						<el-image :src="yan6" :preview-src-list="[yan6]" fit="cover" hide-on-click-modal
							class="img-yan6 margin-top20">
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per flex-sb margin-top20">
					<div class="column-me">
						<el-image :src="yan8" :preview-src-list="[yan8]" fit="cover" hide-on-click-modal
							class="img-yan8">
						</el-image>
						<el-image :src="yan10" :preview-src-list="[yan10]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top20">
						</el-image>
						<el-image :src="yan12" :preview-src-list="[yan12]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top30">
						</el-image>
					</div>
					<div class="column-me margin-top20">
						<el-image :src="yan9" :preview-src-list="[yan9]" fit="cover" hide-on-click-modal
							class="img-yan8">
						</el-image>
						<el-image :src="yan11" :preview-src-list="[yan11]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top20">
						</el-image>
						<el-image :src="yan13" :preview-src-list="[yan13]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top20">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 赛事组织 -->
			<div class="column-me row-center" v-if="tabIndex===2">
				<div class="row-me center-all margin-top50">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">赛事组织</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">EVENT ORGANIZATION</div>
				<div class="row-me margin-top70">
					<div class="intro-text flex1">
						为了真正圆孩子一个赛车手的梦想，让孩子感受赛车运动的氛围，体验竞技体育带来的乐趣和荣誉感，红尾狐卡丁推出并组织了多级别系列赛事活动，所有的赛事均按照专业卡丁车竞赛标准制定比赛规则，确保带给孩子真实的卡丁车赛车体验。目前全国联赛覆盖全国20余个省份，参赛车手约二十万，直接受众群体两百万，是迄今为止中国跨越地区最广、参赛人数最多、规模最大的儿童卡丁车赛事。此外，红尾狐卡丁各训练中心还会组织固定的季赛、年赛和不定期的挑战赛、障碍赛等趣味赛事，在红尾狐卡丁总部协助下区域联赛也将逐步展开，让孩子体验速度与激情的碰撞！
					</div>
				</div>
				<div class="row-me center-all margin-top150">
					<div class="column-me row-center">
						<div class="title">红尾狐卡丁车赛事系统</div>
						<div class="yellow-line margin-top30"></div>
					</div>
				</div>
				<div class="width100Per row-me row-center flex-line margin-top100">
					<div class="flex1"></div>
					<div class="flex1 row-me center-all process-text">
						每三月统计一次排名，前20名<br>获得全国联赛分站赛参赛资格
					</div>
					<div class="flex1"></div>
					<div class="flex1 row-me center-all process-text">
						每年8月举办总决赛
					</div>
				</div>
				<div class="row-me row-center width100Per posRelative margin-top10">
					<img src="../assets/img/process.png" class="width100Per">
					<div class="row-me row-center process-div">
						<div class="flex1 row-me center-all process-title1">门店竞技挑战赛</div>
						<div class="flex1 row-me center-all process-title2">季赛</div>
						<div class="flex1 row-me center-all process-title1">全国联赛分站赛</div>
						<div class="flex1 row-me center-all process-title2">全国总决赛</div>
					</div>
				</div>
				<div class="width100Per row-me margin-top10">
					<div class="flex1 row-me process-text">
						每月统计一次排名，前50名获得季赛参赛资格
					</div>
					<div class="flex1"></div>
					<div class="flex1 row-me process-text">
						每年四月—七月举办一次，前10名获得次年全国总决赛参赛资格
					</div>
					<div class="flex1"></div>
				</div>
			</div>
		</div>
		<div class="black-div margin-top50" v-if="tabIndex===2">
			<div class="img-line1 row-me row-center">
				<img src="../assets/img/line1.png">
			</div>
			<div class="row-me center-all padding-top50">
				<div class="column-me row-center margin-left60 margin-right60">
					<div class="title-white">赛事集锦</div>
					<div class="yellow-line1 margin-top20"></div>
				</div>
			</div>
			<div class="row-me center-all width100Per margin-top50">
				<el-carousel :interval="3000" :pause-on-hover="true" indicator-position="none" type="card" height="20vh"
					style="width: 80%;">
					<el-carousel-item v-for="item in showImgArray" :key="item.id">
						<el-image :src="item.img" class="img-show" fit="cover">
							<template #error>
								<img src="../assets/img/default.png" slot="error"
									class="width100Per height100Per objFitCover">
							</template>
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
	<div class="course" v-else>
		<img src="../assets/img/kczx.png" class="img-banner">
		<div class="main-content">
			<div class="row-me row-center tab-div">
				<div class="row-me center-all left-div">
					<div class="title1">课程中心</div>
					<div class="title2 margin-left20">COURSE</div>
				</div>
				<div class="row-me row-center flex-end flex1 padding-right30">
					<div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">训练课程</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">研学拓展</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">赛事组织</div>
				</div>
			</div>
			<!-- 训练课程 -->
			<div class="column-me row-center" v-if="tabIndex===0">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">训练课程</div>
						<div class="subtitle">TRAINING COURSE</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="introduce margin-top70">
					卡丁车被誉为“F1赛车手的摇篮”，很多顶级赛车手都在卡丁车的座椅上得到启蒙。作为赛车运动的一种，卡丁车也是一项充满魅力的竞技运动，为了让孩子得到专业的启蒙教育，并以培养专业赛车手为目标，红尾狐卡丁组建了一支包括儿童体能训练专家、职业赛车手、儿童行为学学者在内的课程研发团队，先后推出了儿童卡丁车三能课程、全尺寸卡丁车STEAM构建课、迷你卡丁车创造力组装课，并在旗下训练中心开设系列训练课程。
					红尾狐卡丁的培训课程，在提升孩子身体协调性、增强身体素质、促进骨骼发育的同时也进一步增强孩子的专注力和逻辑思维能力，促进智力发展。通过成体系的训练，提升孩子的驾驶技能，塑造自信勇敢的性格。
				</div>
				<div class="row-me row-center width100Per margin-top70">
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(0)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===0">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon1.png" class="img-icon">
							<div class="title11">儿童卡丁车三能课程</div>
						</div>
					</div>
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(1)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===1">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon2.png" class="img-icon">
							<div class="title11">全尺寸卡丁车STEAM构建课</div>
						</div>
					</div>
					<div class="posRelative flex1 row-me center-all card-div anim-bigger-small" @click="clickCard(2)">
						<img src="../assets/img/xun_card1.png" class="img-card" v-if="xunIndex===2">
						<img src="../assets/img/xun_card2.png" class="img-card2" v-else>
						<div class="posAbsoluteAll column-me row-center">
							<img src="../assets/img/xun_icon3.png" class="img-icon">
							<div class="title11">迷你卡丁车创造力组装课</div>
						</div>
					</div>
				</div>
				<div class="column-me margin-top50" v-show="xunIndex===0">
					<div class="row-me row-center">
						<el-image :src="er1" :preview-src-list="[er1]" fit="cover" hide-on-click-modal class="img1">
						</el-image>
						<el-image :src="er2" :preview-src-list="[er2]" fit="cover" hide-on-click-modal
							class="img2 margin-left20">
						</el-image>
					</div>
					<div class="row-me row-center margin-top20">
						<el-image :src="er3" fit="cover" hide-on-click-modal class="img3">
						</el-image>
						<el-image :src="er4" :preview-src-list="[er4]" fit="cover" hide-on-click-modal
							class="img4 margin-left20">
						</el-image>
						<el-image :src="er5" :preview-src-list="[er5]" fit="cover" hide-on-click-modal
							class="img5 margin-left20">
						</el-image>
						<el-image :src="er6" :preview-src-list="[er6]" fit="cover" hide-on-click-modal
							class="img6 margin-left20">
						</el-image>
					</div>
					<div class="row-me row-center margin-top20">
						<el-image :src="er7" :preview-src-list="[er7]" fit="cover" hide-on-click-modal class="img7">
						</el-image>
						<el-image :src="er8" :preview-src-list="[er8]" fit="cover" hide-on-click-modal
							class="img8 margin-left20">
						</el-image>
						<el-image :src="er9" :preview-src-list="[er9]" fit="cover" hide-on-click-modal
							class="img9 margin-left20">
						</el-image>
						<el-image :src="er10" :preview-src-list="[er10]" fit="cover" hide-on-click-modal
							class="img10 margin-left20">
						</el-image>
					</div>
				</div>
				<div class="column-me margin-top50" v-show="xunIndex===1">
					<div class="row-me row-center">
						<el-image :src="quan1" :preview-src-list="[quan1]" fit="cover" hide-on-click-modal
							class="img-quan1">
						</el-image>
						<el-image :src="quan2" :preview-src-list="[quan2]" fit="cover" hide-on-click-modal
							class="img-quan2 margin-left20">
						</el-image>
					</div>
				</div>
				<div class="column-me margin-top50" v-show="xunIndex===2">
					<div class="row-me row-center">
						<el-image :src="mi1" :preview-src-list="[mi1]" fit="cover" hide-on-click-modal class="img-mi1">
						</el-image>
						<el-image :src="mi2" :preview-src-list="[mi2]" fit="cover" hide-on-click-modal
							class="img-mi2 margin-left20">
						</el-image>
						<el-image :src="mi3" :preview-src-list="[mi3]" fit="cover" hide-on-click-modal
							class="img-mi3 margin-left20">
						</el-image>
					</div>
					<div class="row-me row-center margin-top20">
						<el-image :src="mi4" :preview-src-list="[mi4]" fit="cover" hide-on-click-modal class="img-mi4">
						</el-image>
						<el-image :src="mi5" :preview-src-list="[mi5]" fit="cover" hide-on-click-modal
							class="img-mi5 margin-left20">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 研学拓展 -->
			<div class="column-me row-center" v-if="tabIndex===1">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">研学拓展</div>
						<div class="subtitle">STUDIES EXPAND</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="intro-text margin-top70">
					红尾狐卡丁将卡丁车运动和研学拓展进一步结合，依托几处大型训练中心，整合多方资源，开发了几条特色研学路线。拥抱大海，学习帆船驾驶、体验海岛宿营、海洋文化科普；探索冰雪世界，学习滑雪滑冰技能；了解马背文化，感受广阔无垠的大草原。这些内容丰富的研学活动不仅开阔了俱乐部会员的视野，同时也提供了多样的学习体验。
				</div>
				<div class="row-me width100Per margin-top40 flex-sb">
					<div class="column-me">
						<el-image :src="yan1" :preview-src-list="[yan1]" fit="cover" hide-on-click-modal
							class="img-yan1">
						</el-image>
						<el-image :src="yan3" :preview-src-list="[yan3]" fit="cover" hide-on-click-modal
							class="img-yan3 margin-top30">
						</el-image>
						<el-image :src="yan5" :preview-src-list="[yan5]" fit="cover" hide-on-click-modal
							class="img-yan5 margin-top30">
						</el-image>
						<el-image :src="yan7" :preview-src-list="[yan7]" fit="cover" hide-on-click-modal
							class="img-yan7 margin-top30">
						</el-image>
						<div class="yellow-div margin-top30"></div>
					</div>
					<div class="column-me margin-left30">
						<el-image :src="yan2" :preview-src-list="[yan2]" fit="cover" hide-on-click-modal
							class="img-yan2">
						</el-image>
						<el-image :src="yan4" :preview-src-list="[yan4]" fit="cover" hide-on-click-modal
							class="img-yan4 margin-top30">
						</el-image>
						<el-image :src="yan6" :preview-src-list="[yan6]" fit="cover" hide-on-click-modal
							class="img-yan6 margin-top30">
						</el-image>
					</div>
				</div>
				<div class="row-me width100Per flex-sb margin-top30">
					<div class="column-me">
						<el-image :src="yan8" :preview-src-list="[yan8]" fit="cover" hide-on-click-modal
							class="img-yan8">
						</el-image>
						<el-image :src="yan10" :preview-src-list="[yan10]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top30">
						</el-image>
						<el-image :src="yan12" :preview-src-list="[yan12]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top30">
						</el-image>
					</div>
					<div class="column-me margin-left30">
						<el-image :src="yan9" :preview-src-list="[yan9]" fit="cover" hide-on-click-modal
							class="img-yan8">
						</el-image>
						<el-image :src="yan11" :preview-src-list="[yan11]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top30">
						</el-image>
						<el-image :src="yan13" :preview-src-list="[yan13]" fit="cover" hide-on-click-modal
							class="img-yan8 margin-top30">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 赛事组织 -->
			<div class="column-me row-center" v-if="tabIndex===2">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">赛事组织</div>
						<div class="subtitle">EVENT ORGANIZATION</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me margin-top70">
					<div class="intro-text flex1">
						为了真正圆孩子一个赛车手的梦想，让孩子感受赛车运动的氛围，体验竞技体育带来的乐趣和荣誉感，红尾狐卡丁推出并组织了多级别系列赛事活动，所有的赛事均按照专业卡丁车竞赛标准制定比赛规则，确保带给孩子真实的卡丁车赛车体验。目前全国联赛覆盖全国20余个省份，参赛车手约二十万，直接受众群体两百万，是迄今为止中国跨越地区最广、参赛人数最多、规模最大的儿童卡丁车赛事。此外，红尾狐卡丁各训练中心还会组织固定的季赛、年赛和不定期的挑战赛、障碍赛等趣味赛事，在红尾狐卡丁总部协助下区域联赛也将逐步展开，让孩子体验速度与激情的碰撞！
					</div>
					<div class="yellow-div2 margin-left40"></div>
				</div>
				<div class="row-me center-all margin-top150">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">红尾狐卡丁车赛事系统</div>
						<div class="yellow-line margin-top30"></div>
					</div>
				</div>
				<div class="width100Per row-me row-center flex-line margin-top100">
					<div class="flex1"></div>
					<div class="flex1 row-me center-all process-text">
						每三月统计一次排名，前20名<br>获得全国联赛分站赛参赛资格
					</div>
					<div class="flex1"></div>
					<div class="flex1 row-me center-all process-text">
						每年8月举办总决赛
					</div>
				</div>
				<div class="row-me row-center posRelative margin-top10">
					<img src="../assets/img/process.png" class="width100Per">
					<div class="row-me row-center process-div">
						<div class="flex1 row-me center-all process-title1">门店竞技挑战赛</div>
						<div class="flex1 row-me center-all process-title2">季赛</div>
						<div class="flex1 row-me center-all process-title1">全国联赛分站赛</div>
						<div class="flex1 row-me center-all process-title2">全国总决赛</div>
					</div>
				</div>
				<div class="width100Per row-me row-center flex-line margin-top10">
					<div class="flex1 row-me center-all process-text">
						每月统计一次排名，前<br>50名获得季赛参赛资格
					</div>
					<div class="flex1"></div>
					<div class="flex1 row-me center-all process-text">
						每年四月—七月举办一次，前10<br>名获得次年全国总决赛参赛资格
					</div>
					<div class="flex1"></div>
				</div>
			</div>
		</div>
		<div class="black-div margin-top150" v-if="tabIndex===2">
			<div class="img-line1 row-me row-center">
				<img src="../assets/img/line1.png">
			</div>
			<div class="row-me center-all padding-top50">
				<div class="column-me row-center margin-left60 margin-right60">
					<div class="title-white">赛事集锦</div>
					<div class="yellow-line1 margin-top20"></div>
				</div>
			</div>
			<div class="row-me center-all width100Per margin-top50">
				<el-carousel :interval="3000" :pause-on-hover="true" indicator-position="none" type="card"
					height="400px" style="width: 80%;">
					<el-carousel-item v-for="item in showImgArray" :key="item.id">
						<el-image :src="item.img" class="img-show" fit="cover">
							<template #error>
								<img src="../assets/img/default.png" slot="error"
									class="width100Per height100Per objFitCover">
							</template>
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				tabIndex: 0,
				xunIndex: 0,
				er1: require('../assets/img/er1.png'),
				er2: require('../assets/img/er2.png'),
				er3: require('../assets/img/er3.png'),
				er4: require('../assets/img/er4.png'),
				er5: require('../assets/img/er5.png'),
				er6: require('../assets/img/er6.png'),
				er7: require('../assets/img/er7.png'),
				er8: require('../assets/img/er8.png'),
				er9: require('../assets/img/er9.png'),
				er10: require('../assets/img/er10.png'),
				quan1: require('../assets/img/quan1.png'),
				quan2: require('../assets/img/quan2.png'),
				mi1: require('../assets/img/mi1.png'),
				mi2: require('../assets/img/mi2.png'),
				mi3: require('../assets/img/mi3.png'),
				mi4: require('../assets/img/mi4.png'),
				mi5: require('../assets/img/mi5.png'),
				yan1: require('../assets/img/yan1.png'),
				yan2: require('../assets/img/yan2.png'),
				yan3: require('../assets/img/yan3.png'),
				yan4: require('../assets/img/yan4.png'),
				yan5: require('../assets/img/yan5.png'),
				yan6: require('../assets/img/yan6.png'),
				yan7: require('../assets/img/yan7.png'),
				yan8: require('../assets/img/yan8.png'),
				yan9: require('../assets/img/yan9.png'),
				yan10: require('../assets/img/yan10.png'),
				yan11: require('../assets/img/yan11.png'),
				yan12: require('../assets/img/yan12.png'),
				yan13: require('../assets/img/yan13.png'),
				showImgArray: [], //赛事集锦
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {

		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
		},
		// 事件方法执行
		methods: {
			clickIndex(index) {
				this.$router.push({
					path: '/course',
					query: {
						index: index
					}
				})
			},
			clickCard(index) {
				this.xunIndex = index
			},
			//获取赛事集锦
			getAlbum() {
				this.$postRequest('/api/index/getAlbum', {}).then((res) => {
					if (res) {
						this.showImgArray = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {
			$route: {
				handler(to, from) {
					if (to.name.indexOf('course') !== -1) {
						if (this.$route.query.index) {
							this.tabIndex = Number(this.$route.query.index)
						}
						if (this.tabIndex === 2) {
							this.getAlbum()
						}
					}
				},
				deep: true
			},
		},
		activated() {
			if (this.$route.query.index) {
				this.tabIndex = Number(this.$route.query.index)
			}
			if (this.tabIndex === 2) {
				this.getAlbum()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.course-mobile {
		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.img-line {
			width: 80px;
		}

		.title {
			font-size: 28px;
			color: #000000;
			letter-spacing: 2px;
			margin-bottom: 10px;
		}

		.subtitle {
			font-size: 18px;
			color: #666666;
		}

		.main-content {
			padding: 20px;

			.tab-div {
				background: #F4F4F4;
				border-radius: 10px;

				.left-div {
					width: 130px;
					background: #000000;
					border-radius: 10px 0px 0px 10px;
					padding: 10px 0;

					.title1 {
						font-size: 23px;
						color: #FFFFFF;
					}

					.title2 {
						font-size: 15px;
						color: #FFF10C;
						margin-top: 10px;
					}
				}

				.title0 {
					font-size: 18px;
					color: #999999;
					text-align: center;
					padding: 0 10px;
					cursor: pointer;

					&:hover {
						font-weight: bold;
					}

					&-active {
						color: #666666;
						font-weight: bold;
					}
				}

				.tab-line {
					width: 3px;
					height: 20px;
					background: #999999;
					border-radius: 10px;
				}
			}

			.introduce {
				font-size: 22px;
				color: #000000;
				line-height: 35px;
			}

			.card-div {
				cursor: pointer;

				.img-card {
					width: 320px;
					height: 280px;
				}

				.img-card2 {
					width: 350px;
					height: 260px;
				}

				.img-icon {
					width: 110px;
					height: 80px;
					object-fit: fill;
					margin-top: 50px;
				}

				.title11 {
					font-size: 20px;
					color: #333333;
					font-weight: bold;
					margin-top: 15px;
				}
			}

			.img1 {
				width: 100%;
				height: auto;
			}

			.img2 {
				width: 100%;
				height: auto;
			}

			.img3 {
				width: 100%;
				height: auto;
			}

			.img4 {
				width: 100%;
				height: auto;
			}

			.img5 {
				width: 100%;
				height: auto;
			}

			.img6 {
				width: 100%;
				height: auto;
			}

			.img7 {
				width: 100%;
				height: auto;
			}

			.img8 {
				width: 100%;
				height: auto;
			}

			.img9 {
				width: 100%;
				height: auto;
			}

			.img10 {
				width: 100%;
				height: auto;
			}

			.img-quan1 {
				width: 100%;
				height: auto;
			}

			.img-quan2 {
				width: 100%;
				height: auto;
			}

			.img-mi1 {
				width: 100%;
				height: auto;
			}

			.img-mi2 {
				width: 100%;
				height: auto;
			}

			.img-mi3 {
				width: 100%;
				height: auto;
			}

			.img-mi4 {
				width: 100%;
				height: auto;
			}

			.img-mi5 {
				width: 100%;
				height: auto;
			}

			.intro-text {
				font-size: 22px;
				color: #000000;
				line-height: 35px;
			}

			.yellow-div2 {
				width: 200px;
				height: 223px;
				background-color: #F2DC54;
			}

			.img-yan1 {
				width: 100%;
				height: auto;
			}

			.img-yan2 {
				width: 100%;
				height: auto;
			}

			.img-yan3 {
				width: 100%;
				height: auto;
			}

			.img-yan4 {
				width: 100%;
				height: auto;
			}

			.img-yan5 {
				width: 100%;
				height: auto;
			}

			.img-yan6 {
				width: 100%;
				height: auto;
			}

			.img-yan7 {
				width: 100%;
				height: auto;
			}

			.yellow-div {
				width: 700px;
				height: 275px;
				background-color: #F2DC54;
			}

			.img-yan8 {
				width: 100%;
				height: auto;
			}

			.yellow-line {
				width: 100%;
				height: 8px;
				background-color: #F2DC54;
			}

			.process-text {
				font-size: 12px;
				color: #000000;
				font-weight: 300;
			}

			.process-title1 {
				font-size: 10px;
				color: #000000;
				font-weight: 300;
			}

			.process-title2 {
				font-size: 12px;
				color: #FFFFFF;
				font-weight: 300;
			}

			.process-div {
				position: absolute;
				left: 0;
				width: 100%;
			}
		}

		.black-div {
			width: 100%;
			height: 400px;
			background-color: #000000;
			position: relative;

			.img-line1 {
				position: absolute;
				left: 0;
				right: 0;
				top: -10px;

				img {
					width: 100%;
					object-fit: cover;
				}
			}

			.title-white {
				font-size: 35px;
				color: #FFFFFF;
				letter-spacing: 5px;
			}

			.yellow-line1 {
				width: 114px;
				height: 8px;
				background-color: #FADA0F;
			}

			.img-show {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.course {
		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.img-line {
			width: 145px;
			object-fit: scale-down;
			margin-bottom: 10px;
		}

		.title {
			font-size: 48px;
			color: #000000;
			letter-spacing: 5px;
		}

		.subtitle {
			font-size: 24px;
			color: #666666;
		}

		.main-content {
			padding: 80px 100px;

			.tab-div {
				height: 100px;
				background: #F4F4F4;
				border-radius: 10px;

				.left-div {
					width: 330px;
					height: 100px;
					background: #000000;
					border-radius: 10px 0px 0px 10px;

					.title1 {
						font-size: 30px;
						color: #FFFFFF;
					}

					.title2 {
						font-size: 18px;
						color: #FFF10C;
						margin-bottom: -10px;
					}
				}

				.title0 {
					font-size: 26px;
					color: #999999;
					text-align: center;
					padding: 0 30px;
					cursor: pointer;

					&:hover {
						font-weight: bold;
					}

					&-active {
						color: #666666;
						font-weight: bold;
					}
				}

				.tab-line {
					width: 3px;
					height: 30px;
					background: #999999;
					border-radius: 10px;
				}
			}

			.introduce {
				font-size: 26px;
				color: #000000;
				line-height: 48px;
			}

			.card-div {
				cursor: pointer;

				.img-card {
					width: 440px;
					height: 295px;
					object-fit: scale-down;
				}

				.img-card2 {
					width: 440px;
					height: 295px;
					object-fit: fill;
					margin-top: -30px;
				}

				.img-icon {
					width: 192px;
					height: 112px;
					object-fit: scale-down;
					margin-top: 40px;
				}

				.title11 {
					font-size: 26px;
					color: #333333;
					font-weight: bold;
					margin-top: 15px;
				}
			}

			.img1 {
				width: 739px;
				height: 358px;
			}

			.img2 {
				width: 946px;
				height: 358px;
			}

			.img3 {
				width: 178px;
				height: 312px;
			}

			.img4 {
				width: 540px;
				height: 312px;
			}

			.img5 {
				width: 610px;
				height: 312px;
			}

			.img6 {
				width: 315px;
				height: 315px;
			}

			.img7 {
				width: 359px;
				height: 322px;
			}

			.img8 {
				width: 360px;
				height: 322px;
			}

			.img9 {
				width: 322px;
				height: 322px;
			}

			.img10 {
				width: 605px;
				height: 322px;
			}

			.img-quan1 {
				width: 609px;
				height: 812px;
			}

			.img-quan2 {
				width: 1084px;
				height: 812px;
			}

			.img-mi1 {
				width: 557px;
				height: 418px;
			}

			.img-mi2 {
				width: 557px;
				height: 418px;
			}

			.img-mi3 {
				width: 557px;
				height: 418px;
			}

			.img-mi4 {
				width: 841px;
				height: 418px;
			}

			.img-mi5 {
				width: 841px;
				height: 418px;
			}

			.intro-text {
				font-size: 26px;
				color: #000000;
				line-height: 45px;
			}

			.yellow-div2 {
				width: 200px;
				height: 223px;
				background-color: #F2DC54;
			}

			.img-yan1 {
				width: 700px;
				height: 498px;
			}

			.img-yan2 {
				width: 985px;
				height: 736px;
			}

			.img-yan3 {
				width: 700px;
				height: 498px;
			}

			.img-yan4 {
				width: 985px;
				height: 664px;
			}

			.img-yan5 {
				width: 700px;
				height: 498px;
			}

			.img-yan6 {
				width: 985px;
				height: 1319px;
			}

			.img-yan7 {
				width: 700px;
				height: 887px;
			}

			.yellow-div {
				width: 700px;
				height: 275px;
				background-color: #F2DC54;
			}

			.img-yan8 {
				width: 842px;
				height: 620px;
			}

			.yellow-line {
				width: 387px;
				height: 8px;
				background-color: #F2DC54;
			}

			.process-text {
				font-size: 24px;
				color: #000000;
				font-weight: 300;
			}

			.process-title1 {
				font-size: 30px;
				color: #000000;
				font-weight: 300;
			}

			.process-title2 {
				font-size: 30px;
				color: #FFFFFF;
				font-weight: 300;
			}

			.process-div {
				position: absolute;
				left: 0;
				width: 100%;
			}
		}

		.black-div {
			width: 100%;
			height: 700px;
			background-color: #000000;
			position: relative;

			.img-line1 {
				position: absolute;
				left: 0;
				right: 0;
				top: -55px;

				img {
					width: 100%;
					object-fit: cover;
				}
			}

			.title-white {
				font-size: 48px;
				color: #FFFFFF;
				letter-spacing: 5px;
			}

			.yellow-line1 {
				width: 114px;
				height: 8px;
				background-color: #FADA0F;
			}

			.img-show {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
</style>
